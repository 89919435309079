import React from "react"
import { graphql } from 'gatsby'
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";
import Blogs from "../components/BlogPage/Blogs";
import { Helmet } from "react-helmet";

const useCasesTemplate = ({ data }) => {
  const metaData = {
    metaTitle: data.strapiCategories.Name,
    metaDescription: data.strapiCategories.Name
  }

  return (
    <>
        <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>
        <SEO seo={metaData} />
        <LayoutHeadFoot>
            <div className="gradientBlue">
            <div className="py-14" />
                <div className="lg:container px-4 lg:px-0 mb-24">
                    <h2 className="article-header">{data.strapiCategories.Name}</h2>
                    <Blogs blogs={data?.allStrapiBlogs?.nodes}/>
                </div>
            </div>
        </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query strapiBlogsByCategoriesQuery($id: Int!) {
    allStrapiBlogs(filter: {categories: {elemMatch: {id: {eq: $id}}}}) {
        nodes {
            URL
            Description
            Author
            Header
            Thumbnail {
                localFile {
                    publicURL
                }
            }
            categories {
                Name
                URL
            }
            PublishedAt
            Type
        }
    }
    strapiCategories(strapiId: {eq: $id}) {
        Name
    }
}`

export default useCasesTemplate;
